import { ServerError, useMutation, useApolloClient } from '@apollo/client';
import { SpecificOrder } from 'types/orders.types';
import useToast from 'hooks/useToast';
import { ValuesOrderStatusEnum } from 'components/orders/orders.enums';
import { ORDER_STATUS_UPDATE } from './orders.mutations';

const FIFTH_DAY_ERROR = 'Orders from the past month cannot be updated after the 5th day.'
const ORDER_UPDATED_ERROR = 'Order updated since retrieval'
type useOrderUpdateStatusResponse = {
  updateOrderStatus: {
    id: number,
    order_status: ValuesOrderStatusEnum,
    payment_status: string,
  }
}

const useOrderUpdateStatus = () => {
  const [openToast] = useToast();
  const client = useApolloClient();

  return useMutation<{ updateOrderStatus: SpecificOrder }>(
    ORDER_STATUS_UPDATE,
    {
      refetchQueries: ['ordersPaginated', 'specificOrder'],
      awaitRefetchQueries: true,
      onCompleted: (res: useOrderUpdateStatusResponse) => {
        const updatedOrderId = res?.updateOrderStatus?.id

        if (updatedOrderId) {
          openToast(`Orden ${updatedOrderId} actualizada`, { type: 'success' });
        }
      },
      onError: (error) => {
        const response = (error.networkError as ServerError)?.result || error.graphQLErrors;
        const errorStr = JSON.stringify(response);

        if (errorStr.includes(FIFTH_DAY_ERROR)) {
          openToast('No es posible actualizar órdenes del mes pasado después del 5to día del mes', { type: 'error' });
        } else if (errorStr.includes(ORDER_UPDATED_ERROR)) {
          openToast('La orden ha cambiado desde la última visualización', {
            type: 'warning',
            textButton: 'Recargar',
            onClick: () => {
              client.refetchQueries({
                include: ['ordersPaginated', 'specificOrder'],
              });
            }
          });
        } else {
          openToast('Error al actualizar la orden', { type: 'error' });
        }

        throw new Error(errorStr);
      }
    }
  );
};

export default useOrderUpdateStatus;
