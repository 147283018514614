import { gql } from '@apollo/client';

const order = gql`
  fragment OrderFragment on Order {
    approved_date
    bought_date
    cancellation_reason
    cdso
    cdso_transaction
    coupon
    comments
    created_at
    credit_date
    details
    distribution_center
    driver
    expected_delivery_date
    folio
    id
    in_process_date
    is_special_pickup
    max_delivery_date
    order_status
    origin
    patient
    payment_date
    payment_method
    payment_status
    product_images
    products_count
    shipping
    shipping_method
    stock_details
    store
    supervised_by
    tags
    total
    updated_at
  }
`;

const specificOrder = gql`
  fragment SpecificOrderFragment on SpecificOrder {
    cancellation_reason
    cdso
    cfdi
    comments
    coupon
    created_at
    credit_date
    deleted_at
    delayed_reason
    details
    discount
    distribution_center
    driver
    expected_delivery_date
    external_payment_id
    fee_cost
    folio
    generated_by
    id
    identification
    insurance_refund_status
    in_process_date
    invoicing
    iva
    is_special_pickup
    logistics_supplier
    max_delivery_date
    order_status
    orbis
    origin
    patient
    payment
    payment_date
    payment_method
    payment_status
    prescriptions
    ready_date
    receiving_date
    referred_by
    rejection_reason
    shipping
    shipping_cost
    shipping_date
    shipping_discount
    shipping_method
    shipping_price
    shipping_price_without_iva
    store
    subscription
    subtotal
    supervised_by
    tags
    total
    tracking_code
    updated_at
    updated_by
  }
`;

const purchaseOrder = gql`
  fragment PurchaseOrderFragment on PurchaseOrder {
    created_at
    created_by
    details
    expected_delivery_date
    external_id
    id
    invoice_code
    iva
    status
    store
    subtotal
    supplier
    total
    updated_at
    updated_by
  }
`;

const fragments = {
  order,
  specificOrder,
  purchaseOrder,
};

export default fragments;
