import { ID } from './util.types';

// distribution_center Enum
// ** storesNameEnum = cedisenum
// * Here we can add the distribution centers that we have in the system
// * There are being added as we need them as enum values
// */
export enum storesNameEnum {
  ALMACEN_MONTERREY = 'Almacén Monterrey',
  SUCURSAL_MONTERREY = 'Sucursal Monterrey',
  SUCURSAL_CDMX = 'Sucursal Cd. Mx.',
  AMAZON_FULL = 'Amazon FULL',
  HOSPITARIA = 'Hospitaria',
  HOSPITARIA_CAIH = 'Hospitaria CAIH',
  HOSPITARIA_VITAU = 'Hospitaria Vitau',
  FLORA_FERTILIDAD = 'Flora Fertilidad',
  SWISS_HOSPITAL = 'Swiss Hospital',
  PRIMER_FRENTE = 'Primer Frente',
  OSSIS = 'Ossis',
  CODEFAR = 'CODEFAR',
  A5_EXPRESS = 'A5 Express'
}

export type Store = {
  id: ID;
  created_at: Date;
  updated_at: Date;
  street: string;
  exterior_number: string;
  interior_number: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  phone: string;
  additional_info: string;
  name: string | storesNameEnum;
  company_name: string;
  business_hours: string;
  has_pickup: boolean
};

export const VitauStores = [
  storesNameEnum.ALMACEN_MONTERREY,
  storesNameEnum.SUCURSAL_MONTERREY
]
